<template>
  <v-container fluid>
    <h3 class="mt-1">Salkku</h3>
    <small class="mb-1">
      {{ stats.updateTime ? `Päivitetty ${formatDateAndTime(stats.updateTime)}` : "" }}
    </small>
    <v-row dense>
      <!-- priceWithTaxes -->
      <v-col cols="12" sm="6" md="6" xl="4">
        <basic-card
          title="Hankintahinta"
          subtitle="Vuokrakohteiden hankintahinta yht."
          icon="cash-minus"
          :amount="`${formatCurrency(stats.apartment.totalPurchasePrice, 0, 0)}`"
          color=""
        ></basic-card>
      </v-col>
      <!-- estimatedPrice -->
      <v-col cols="12" sm="6" md="6" xl="4">
        <basic-card
          title="Markkina-arvo"
          subtitle="Vuokrakohteiden markkinahinta yht."
          icon="currency-eur"
          :amount="`${formatCurrency(stats.apartment.combinedPrice, 0, 0)}`"
          color=""
        ></basic-card>
      </v-col>
      <!-- price change -->
      <v-col cols="12" sm="6" md="6" xl="4">
        <basic-card
          title="Arvonmuutos"
          subtitle="Potentiaalinen arvonmuutos"
          :icon="priceChange < 0 ? 'arrow-bottom-right' : 'arrow-top-right'"
          :amount="`${formatCurrency(priceChange, 0, 0)}`"
          :color="priceChange < 0 ? 'error' : 'success'"
        ></basic-card>
      </v-col>
    </v-row>

    <h3 class="mt-1">Pääomat</h3>
    <small class="mb-1">
      {{ stats.updateTime ? `Päivitetty ${formatDateAndTime(stats.updateTime)}` : "" }}
    </small>
    <v-row dense>
      <!-- Own equity -->
      <v-col cols="12" sm="6" md="6" xl="4">
        <basic-card
          title="Oma pääoma"
          subtitle="Oma pääoma yhteensä"
          icon="cash"
          :amount="`${formatCurrency(calcOwnEquity(), 0, 0)}`"
          color="success"
          tooltip="Kohteiden markkinahinnat tai ostohinnat - omat lainat - yhtiölainat"
        ></basic-card>
      </v-col>

      <!-- Total loans left -->
      <v-col cols="12" sm="6" md="6" xl="4">
        <basic-card
          title="Pankkilainat"
          subtitle="Jäljellä oleva lainapääoma"
          icon="bank-outline"
          :amount="`${formatCurrency(stats.loans.currentLoanAmount, 0, 0)}`"
          color="error"
        ></basic-card>
      </v-col>

      <!-- Total apartment loans left -->
      <v-col cols="12" sm="6" md="6" xl="4">
        <basic-card
          title="Yhtiölainat"
          subtitle="Kohteiden yhtiölainaosuudet"
          icon="home-minus"
          :amount="`${formatCurrency(stats.apartment.housingCompanyLoans, 0, 0)}`"
          color="warning"
        ></basic-card>
      </v-col>

      <!-- Total free loan value -->
      <v-col class="mt-lg-1" cols="12" sm="6" md="6" xl="4">
        <h3>Vakuudet ja luototus</h3>
        <small class="mb-1">
          {{ stats.updateTime ? `Päivitetty ${formatDateAndTime(stats.updateTime)}` : "" }}
        </small>
        <basic-card
          class="mb-1"
          title="Luototusaste (LTV)"
          subtitle="Nettovelkojen osuus kohteiden arvosta"
          icon="bank"
          :amount="`${calcLtv()} %`"
          :color="calcLtv() > 50 ? 'error' : 'success'"
        ></basic-card>

        <basic-card
          title="Vapaat vakuudet"
          subtitle="70% vakuusarvolla laskettuna"
          :icon="calcFreeLoanValue() < 0 ? 'battery-outline' : 'battery-medium'"
          :amount="`${formatCurrency(calcFreeLoanValue(), 0, 0)}`"
          :color="calcFreeLoanValue() < 0 ? 'error' : 'success'"
        ></basic-card>
      </v-col>

      <!-- Total free loan value -->
      <v-col class="mt-lg-1" cols="12" sm="6" md="6" xl="4">
        <loans-pie-chart
          v-if="!statisticsLoading"
          style="max-height: 300px"
          :ownEquity="calcOwnEquity()"
          :fontFamily="getFontFamily"
        ></loans-pie-chart>
      </v-col>

      <v-col class="mt-lg-1" cols="12" sm="6" md="6" xl="4">
        <h3>Tunnusluvut</h3>
        <small class="mb-1">
          {{ stats.updateTime ? `Päivitetty ${formatDateAndTime(stats.updateTime)}` : "" }}
        </small>

        <basic-card
          class="mb-1"
          title="ROI"
          subtitle="Sijoitetun pääoman tuotto ennen veroja"
          icon="percent-outline"
          :amount="`${calcRoi()}`"
          color=""
          tooltip="(Vuokrat (tuotetyyppi) - hoitovastikkeet) * 12)) / kohteiden ostohinnat"
        ></basic-card>
        <basic-card
          title="ROE"
          subtitle="Oman pääoman tuotto ennen veroja"
          icon="percent-outline"
          :amount="`${calcRoe()}`"
          color=""
          tooltip="(Vuokrat (tuotetyyppi) - hoitovastikkeet) * 12)) / sijoitettu oma pääoma"
        ></basic-card>
      </v-col>
    </v-row>

    <v-row dense>
      <!-- Average ROI -->
      <v-col cols="12" sm="6" md="6" xl="4"> </v-col>

      <!-- Average ROE -->
      <v-col cols="12" sm="6" md="6" xl="4"> </v-col>
    </v-row>
  </v-container>
</template>

<script>
import mixins from "../../mixins/mixins";
import BasicCard from "../../components/Statistics/BasicCard";
import LoansPieChart from "../Charts/Overview/LoansPieChart.vue";
import { mapState, mapGetters } from "vuex";

export default {
  components: {
    BasicCard,
    LoansPieChart,
  },

  mixins: [mixins],

  data() {
    return {
      tenantCount: 0,
      keyHolderCount: 0,
      contractCount: 0,
      apartmentCount: 0,
      partnerCount: 0,
      occupancyRate: 0,
    };
  },

  computed: {
    ...mapState("statistics", ["stats"]),
    ...mapGetters("account", ["getFontFamily"]),

    statisticsLoading() {
      return this.$store.state.statistics.statisticsLoading && this.getFontFamily;
    },

    priceChange() {
      return this.stats.apartment.combinedPrice - this.stats.apartment.totalPurchasePrice;
    },
  },

  methods: {
    calcRoi() {
      const totalRentIncomes = this.stats.invoice.rentIncomes;
      const maintenanceTotal = this.stats.apartment.maintenanceChargesThisMonth;
      const totalPurchasePrice = this.stats.apartment.totalPurchasePrice;

      if (totalPurchasePrice <= 0) {
        return "Ei laskettavissa";
      } else {
        const total = this.roundDecimals(
          (((totalRentIncomes - maintenanceTotal) * 12) / totalPurchasePrice) * 100
        );

        return total + " %";
      }
    },

    calcRoe() {
      const totalRentIncomes = this.stats.invoice.rentIncomes;
      const maintenanceTotal = this.stats.apartment.maintenanceChargesThisMonth;
      const totalPurchasePrice = this.stats.apartment.totalPurchasePrice;
      const currentLoanAmount = this.stats.loans.currentLoanAmount;
      const apartmentLoanAmount = this.stats.apartment.housingCompanyLoans;

      if (totalPurchasePrice <= 0) {
        return "Ei laskettavissa";
      } else {
        const total = this.roundDecimals(
          (((totalRentIncomes - maintenanceTotal) * 12) /
            (totalPurchasePrice - currentLoanAmount - apartmentLoanAmount)) *
            100
        );

        return total + " %";
      }
    },

    calcOwnEquity() {
      return (
        this.stats.apartment.combinedPrice -
        this.stats.loans.currentLoanAmount -
        this.stats.apartment.housingCompanyLoans
      );
    },

    calcFreeLoanValue() {
      const combinedPrice = this.stats.apartment.combinedPrice;
      const currentLoanAmount = this.stats.loans.currentLoanAmount;
      const apartmentLoanAmount = this.stats.apartment.housingCompanyLoans;
      return combinedPrice * 0.7 - currentLoanAmount - apartmentLoanAmount;
    },

    calcLtv() {
      const combinedPrice = this.stats.apartment.combinedPrice;
      const currentLoanAmount = this.stats.loans.currentLoanAmount;
      const apartmentLoanAmount = this.stats.apartment.housingCompanyLoans;

      if (!combinedPrice || combinedPrice == 0) {
        return 0;
      }

      return this.roundDecimals(((currentLoanAmount + apartmentLoanAmount) / combinedPrice) * 100);
    },
  },
};
</script>

<style scoped></style>
