<template>
  <v-container fluid>
    <h3>Yleiset</h3>
    <small class="mb-1">
      {{ stats.updateTime ? `Päivitetty ${formatDateAndTime(stats.updateTime)}` : "" }}
    </small>
    <v-row dense>
      <!-- RentalContracts -->
      <v-col cols="12" sm="6" md="4" lg="3" xl="2">
        <basic-card
          title="Vuokrasopimukset"
          subtitle="Voimassa olevat vuokrasopimukset"
          icon="file-document-edit-outline"
          :amount="`${stats.contract.activeContractCount} kpl`"
          color="blue"
        ></basic-card>
      </v-col>
      <!-- Apartments -->
      <v-col cols="12" sm="6" md="4" lg="3" xl="2">
        <basic-card
          title="Kohteet"
          subtitle="Vuokrattavat kohteet yhteensä"
          icon="home-outline"
          :amount="`${stats.apartment.activeApartmentCount} kpl`"
          color="yellow"
        ></basic-card>
      </v-col>
      <!-- Tenants -->
      <v-col cols="12" sm="6" md="4" lg="3" xl="2">
        <basic-card
          title="Vuokralaiset"
          subtitle="Vuokralaisia yhteensä"
          icon="account-check"
          :amount="`${stats.tenant.activeTenantCount} kpl`"
          color="success"
        ></basic-card>
      </v-col>
      <!-- KeyHolders -->
      <v-col cols="12" sm="6" md="4" lg="3" xl="2">
        <basic-card
          title="Avaimenhaltijat"
          subtitle="Avaimenhaltijoita yhteensä"
          icon="account-key"
          :amount="`${stats.tenant.activeKeyHolderCount} kpl`"
          color="success"
        ></basic-card>
      </v-col>
      <!-- Partners -->
      <v-col cols="12" sm="6" md="4" lg="3" xl="2">
        <basic-card
          title="Toimeksiantajat"
          subtitle="Toimeksiantajia yhteensä"
          icon="account-group"
          :amount="`${stats.partner.activePartnerCount} kpl`"
          color="blue"
        ></basic-card>
      </v-col>
      <!-- Occupancy rate -->
      <v-col cols="12" sm="6" md="4" lg="3" xl="2">
        <basic-card
          title="Käyttöaste"
          subtitle="Toiminnallinen käyttöaste 2 kk - 12 kk"
          icon="percent-outline"
          :amount="`${this.roundDecimals(
            stats.apartment.occupancyRates.months2,
            1
          )} - ${this.roundDecimals(stats.apartment.occupancyRates.months12, 1)} %`"
          color="success"
        ></basic-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import mixins from "../../mixins/mixins";
import BasicCard from "../../components/Statistics/BasicCard";
import { mapState } from "vuex";

export default {
  components: {
    BasicCard,
  },

  mixins: [mixins],

  data() {
    return {
      tenantCount: 0,
      keyHolderCount: 0,
      contractCount: 0,
      apartmentCount: 0,
      partnerCount: 0,
    };
  },

  computed: {
    ...mapState("statistics", ["stats"]),
  },
};
</script>

<style scoped></style>
